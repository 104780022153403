import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L2.scss';

export default class B11U1L2 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      // <div class="contenedor"> 
      // <div className="App">
      // <header className="App-header">
      <Grid style={{ backgroundColor: '#101010', height: '160vh'}}>
         <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
        <h2 className="lesson2title"><br></br>Lesson 2</h2>
        <Slider {...settings}>
        <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-0.jpg?alt=media&token=21c3c71f-4e74-4e5d-8503-3970ebe7889b'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-1.jpg?alt=media&token=5f8b8247-2ae8-4080-be4d-eb992870d335'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-2.jpg?alt=media&token=ada08254-5f75-409a-9ec5-2aca6c87c6fb'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-3.jpg?alt=media&token=2721623d-73a6-49d5-98e7-fbb3dca0172e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-4.jpg?alt=media&token=b61ab54d-fe0b-40ea-9c07-15e80e6f9f28'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-5.jpg?alt=media&token=1e6365ad-2a55-49db-84a1-54398caf68d3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-6.jpg?alt=media&token=adf959cb-2eae-4cc8-af5a-9ef8cc2331ab'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-7.jpg?alt=media&token=2da48ff5-0d18-47c7-aa53-579f9f9c3a0c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-8.jpg?alt=media&token=428ee8ab-ad4b-4be7-aeec-304199ab4048'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-9.jpg?alt=media&token=1678c4f4-cdbc-4238-831b-053af037814b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-10.jpg?alt=media&token=f5d60c80-f13d-4fd9-9b91-194100d5be49'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-11.jpg?alt=media&token=27b06c35-3535-40dc-b597-ae4905daff3e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-12.jpg?alt=media&token=09c8cee4-729e-4f1e-bc1e-ba79cbeddc6c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-13.jpg?alt=media&token=e7e0f9dd-2e9f-477a-a46a-679272e00f42'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-14.jpg?alt=media&token=ad6b193a-f8f3-4cdb-b4a1-970e756dc1b1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-15.jpg?alt=media&token=ebdbe17c-831c-48ed-b286-fc90e58adfaf'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-16.jpg?alt=media&token=34a17ed2-fd92-43b9-ba85-f59b79ac215b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-17.jpg?alt=media&token=ce0e2e77-a48c-4f24-8035-a6d89e76cd49'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-18.jpg?alt=media&token=ab3dfe83-dd3f-4bcd-8fad-89e7fe254673'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-19.jpg?alt=media&token=902077b6-7489-4262-84c2-9568a5de28ec'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-20.jpg?alt=media&token=85068b62-2fb8-46c8-9b11-5e81e114b5b0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-21.jpg?alt=media&token=900bdce6-de60-4c0b-9ede-6130df0a20d2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-22.jpg?alt=media&token=f4aeb373-cb23-438c-9cc9-eb1bbb3ca17f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-23.jpg?alt=media&token=c7dfeda7-3a5f-4f81-9e5e-30873b18c84f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-24.jpg?alt=media&token=424e59f7-a7bf-4381-8d59-d2c6f86bb894'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-25.jpg?alt=media&token=fca290ed-8cea-47cc-b508-884f60ae9b50'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-26.jpg?alt=media&token=db6d5f83-469a-436c-98c2-ed98f7a6f90b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-27.jpg?alt=media&token=bdf51eeb-4171-4fb4-b4c3-07d47a78d527'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-28.jpg?alt=media&token=e3e68112-425f-4912-a507-7267c3756d3d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-29.jpg?alt=media&token=0115cf0a-c8c7-4249-919a-7753092fd8f1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-30.jpg?alt=media&token=3f56cbc4-1015-48a7-8567-6828cb21f806'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2F1aa848ac428dc26eadf4728da39c94b0-31.jpg?alt=media&token=1648ac33-edbd-4c47-8883-4c85b3ce58cf'} alt="boohoo" className="img-responsive"/>
          </div>

        </Slider>
        <div class="center"> 
        <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L2%2FA22U2L2NREF.pdf?alt=media&token=625b2ad7-deac-4c09-bbe0-abc0ea96e521' download className="btn-download-dos">Download resources</Button>
       </div>
        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

</Grid>
        
      //   </header>
      // </div>
      // </div>
    
    );
  }
}
