import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L5.scss'

export default class B11U1L5 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid style={{ backgroundColor: '#101010'}}>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2 className="lesson5title"><br></br>Lesson 5</h2>

        <Slider {...settings}>

        <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-0.jpg?alt=media&token=53262b59-889c-4224-8c01-477a4a4a231c'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-1.jpg?alt=media&token=5fb3a36b-9245-4b17-8057-7f96a4b9bdc4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-2.jpg?alt=media&token=d5e5acf3-85f9-45ef-b1f2-1e2ed57e5f4b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-3.jpg?alt=media&token=ae9feb25-bacc-4290-bcb2-fb5e46ec13fd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-4.jpg?alt=media&token=0fc2b929-f420-4cb0-91d9-d49edb478602'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-5.jpg?alt=media&token=468c94ce-f3cf-4e3a-a279-919439359c7e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-6.jpg?alt=media&token=cfaba73f-d347-4386-b035-68af9d9aba3d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-7.jpg?alt=media&token=d666f1be-259e-4a7e-8866-d34a7edbae77'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-8.jpg?alt=media&token=7b9af34e-1e42-4db3-a32d-2840061b868d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-9.jpg?alt=media&token=b8fb1fae-3dd0-402a-afdf-a90eb9b1e40d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-10.jpg?alt=media&token=855ae396-c9f8-40ed-ac51-c5327da0a2e9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-11.jpg?alt=media&token=d14b2151-91e9-4dd9-bd73-de6d38c0c815'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-12.jpg?alt=media&token=17d97752-0350-40d8-9b2a-ce44ecc8956b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-13.jpg?alt=media&token=6df7c35f-d172-4f63-b8cf-ada1642b0c8f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-14.jpg?alt=media&token=a6180523-feb7-4236-95dc-ac187e939fac'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-15.jpg?alt=media&token=1d86373f-c3fb-41df-a657-0a42fb3a0355'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-16.jpg?alt=media&token=c839bce3-c1a0-4033-832b-54f45a5e65a0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-17.jpg?alt=media&token=881896ff-e9aa-49a4-91e0-1226f8b47024'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-18.jpg?alt=media&token=5feb9654-bc54-47e4-b165-f50ed5183baf'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-19.jpg?alt=media&token=1ab714df-2661-4fbe-843e-3ce0bfab2643'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-20.jpg?alt=media&token=545fa7b4-179d-49b9-bc54-42e1a4f15652'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-21.jpg?alt=media&token=1ff7945c-11b1-4273-8f5b-126d1a0f5914'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-22.jpg?alt=media&token=6cfec9bd-e002-4aa6-92a5-ce9031df3763'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-23.jpg?alt=media&token=50fa8269-4175-439e-b3c1-48d31ebff7a8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-24.jpg?alt=media&token=a0597754-787f-4685-a37c-c5d1f43332b0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-25.jpg?alt=media&token=62ab8e50-bbea-43df-920d-56edfd41fd06'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-26.jpg?alt=media&token=4b1a497d-deb4-4365-a2c2-91b1794f5702'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-27.jpg?alt=media&token=22cd2e43-8237-484d-b7d4-88e3a3e723c0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-28.jpg?alt=media&token=69b09b72-17ba-455e-ab2b-2a0693163e72'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-29.jpg?alt=media&token=7e7d9b66-59f0-4708-93e7-2537de463477'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-30.jpg?alt=media&token=fd922ab8-19b1-4296-bc93-61966ad184e5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-31.jpg?alt=media&token=037dc1bb-2ddd-437d-8726-169ff5dbbee2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L5%2F4baf6da7c70bc269da65054a67a27ece-32.jpg?alt=media&token=cb082186-bdf6-43b9-8a81-d349ca1169e5'} alt="boohoo" className="img-responsive"/>
          </div>

        </Slider>
   
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
