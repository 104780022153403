import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L3.scss';

export default class B11U1L3 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (

<Grid style={{ backgroundColor: '#101010'}}>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    
        <h2 className="lesson3title"><br></br>Lesson 3</h2>
        <Slider {...settings}>
        <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-0.jpg?alt=media&token=08359771-26fe-422a-a0b7-b4610917a460'} alt="boohoo" className="img-responsive" />
              <br />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-1.jpg?alt=media&token=e9e1eb67-dc6d-4783-9791-50f71152cc29'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-2.jpg?alt=media&token=8443256e-2a8e-451e-a046-3d8160bd0de7'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-3.jpg?alt=media&token=73a316dc-ae7f-49ee-94dc-e2c343ed0312'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-4.jpg?alt=media&token=2de9341e-278c-4f82-825a-e7fd0e6afdcf'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-5.jpg?alt=media&token=f0f1d7c8-23e3-4e88-80f8-b53eac7fe697'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-6.jpg?alt=media&token=5107e593-47ad-4202-8db0-2fa0cf760add'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-7.jpg?alt=media&token=69222d3e-1cf0-43b9-b7ef-00aa108039bf'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-8.jpg?alt=media&token=6a3dbbf3-99df-485b-b5d0-85d4f0e8b87f'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-9.jpg?alt=media&token=c2d5d225-18b1-4118-8df8-1eff409e1f65'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-10.jpg?alt=media&token=c23d418c-130d-4de9-8bab-6331a35e20eb'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-11.jpg?alt=media&token=e7db6256-90aa-4acd-a919-3592ef676b3a'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-12.jpg?alt=media&token=c06d00eb-3cc8-49f3-9fca-ed96035bb5c1'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-13.jpg?alt=media&token=5f2031d8-7e38-4ccf-bc77-9280d071d99d'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-14.jpg?alt=media&token=5df6cea6-0d6d-4281-9b41-b033af3fc864'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-15.jpg?alt=media&token=52b389c0-435d-41ae-acfd-303c494323ed'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-16.jpg?alt=media&token=891ce859-80e9-4ca8-a6f2-f429dde3902c'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-17.jpg?alt=media&token=fb9a584e-1b4a-498b-bf8d-4d5032c83b95'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-18.jpg?alt=media&token=0b766bea-f68f-48da-8de0-fbdba5245c39'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-19.jpg?alt=media&token=a04c0d41-9ccf-4f3c-9c76-29beb0a4df52'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-20.jpg?alt=media&token=3e9f19ef-ceac-4716-99ac-20b9beda9c41'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-21.jpg?alt=media&token=061cdd90-a00c-4d0e-a978-652669f86308'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-22.jpg?alt=media&token=cc5192b7-64c6-414c-86df-bf6208035549'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-23.jpg?alt=media&token=361f0de5-07c7-4a20-9235-ff98bb9f69ed'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-24.jpg?alt=media&token=c9628ce7-a2e9-4fa8-bb75-75825dcf7005'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-25.jpg?alt=media&token=b46c3067-4b59-4d8f-8f8f-c726a832753e'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-26.jpg?alt=media&token=f310e7fb-8dc3-490b-92ba-e76b6ac67e96'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-27.jpg?alt=media&token=c469a02c-8e61-447e-b422-2c28a3f6219f'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-28.jpg?alt=media&token=80bae528-52b9-4af3-860d-93db356c732b'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-29.jpg?alt=media&token=d7ddc964-5bd9-4363-ad36-55ebac5f52f5'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L3%2F61833ed64ddf7d508a25f57dce910db7-30.jpg?alt=media&token=ff9a96cb-07b9-4ada-ba33-c338dbc637be'} alt="boohoo" className="img-responsive" />
            </div>

        </Slider>
        

</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

  
    );
  }
}


    