
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-0.jpg?alt=media&token=83880f8b-2630-473f-982a-a3bbc75b20da'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
  
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-1.jpg?alt=media&token=7c609f0b-7879-4ef7-894b-e59ff930b9a8'} alt="boohoo" className="img-responsive"/>
          </div> 
      
        
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-2.jpg?alt=media&token=60927621-f446-448c-acf2-dea9611828d8'} alt="boohoo" className="img-responsive"/>
          </div>
 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-3.jpg?alt=media&token=a26e00ee-b723-42c6-8c7e-e7e140b304f1'} alt="boohoo" className="img-responsive"/>
          </div>
     
        
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-4.jpg?alt=media&token=82631dff-6ba1-4eb9-a467-125a18bc62a2'} alt="boohoo" className="img-responsive"/>
          </div>
    
      
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-5.jpg?alt=media&token=b7723967-25de-49fa-ab60-a2087a58ea41'} alt="boohoo" className="img-responsive"/>
          </div>
      
 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-6.jpg?alt=media&token=5c1a0801-95a9-442a-acac-4d687e3d4a50'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-7.jpg?alt=media&token=eda73faf-d478-446f-94da-548ab41d5b9a'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-8.jpg?alt=media&token=ad5a9976-27e6-4b4f-96b4-d05b846b5817'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-9.jpg?alt=media&token=4629aca1-568c-4804-990c-bff9198b3539'} alt="boohoo" className="img-responsive"/>
          </div>
    

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-10.jpg?alt=media&token=798d4e39-5387-43ea-9da7-bbbb3b4c8b62'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-11.jpg?alt=media&token=757daafc-9147-438d-b967-09c0d79c3489'} alt="boohoo" className="img-responsive"/>
          </div>
      

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-12.jpg?alt=media&token=398317a9-5aa2-4812-937e-94824baf9951'} alt="boohoo" className="img-responsive"/>
          </div>
          

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-13.jpg?alt=media&token=d76ce3fb-13fb-4dc6-975a-8565a3225bdf'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-14.jpg?alt=media&token=fec259d1-3760-48c2-8bc6-0087016cc8e8'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-15.jpg?alt=media&token=0d5897d2-076b-4ecf-ab03-7b7360527515'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-16.jpg?alt=media&token=5f93d584-0aa7-4290-a610-0075196efcd7'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-17.jpg?alt=media&token=765a9f1e-c9ba-43c7-bdd0-a63a57ae3361'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-18.jpg?alt=media&token=f4bd6707-d14a-416d-af59-ebe801323597'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-19.jpg?alt=media&token=3a27a98f-aab9-45ab-a7a3-009547f2e57f'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-20.jpg?alt=media&token=b3ca3f75-2437-4bbd-9bff-7ed70e7a0952'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-21.jpg?alt=media&token=06addb6e-8974-46f7-9315-9146ab445f0e'} alt="boohoo" className="img-responsive"/>
          </div>
    

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-22.jpg?alt=media&token=1495d6e6-a020-4a35-a56c-a463c64caacc'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-23.jpg?alt=media&token=e1842670-563f-4116-9ccf-4d98e401bb33'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-24.jpg?alt=media&token=8560252c-842c-42c4-8663-0213e01bdc27'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-25.jpg?alt=media&token=afcb88f3-8f82-43a2-86a9-7cbbf812e63b'} alt="boohoo" className="img-responsive"/>
          </div>
      

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-26.jpg?alt=media&token=1e507c94-b62e-4b26-87b8-73304d875c10'} alt="boohoo" className="img-responsive"/>
          </div>
    

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-27.jpg?alt=media&token=14c07402-274d-4846-938c-d9df157feb84'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-28.jpg?alt=media&token=6d3953ff-ec41-4f02-9f53-6cf1a628116e'} alt="boohoo" className="img-responsive"/>
          </div>
       

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-29.jpg?alt=media&token=8d67090f-71d0-484f-822c-2d7cbf04f3fc'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-30.jpg?alt=media&token=e002bc6e-bc34-4b89-b90b-f8e58cdc9d4f'} alt="boohoo" className="img-responsive"/>
          </div>
          

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-31.jpg?alt=media&token=40cb3545-5ba8-46ab-9cba-158e0401d82e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-32.jpg?alt=media&token=e946b0bf-54fd-4d28-8cfe-63caccc3b2d1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2F1ef9fc6c0a5b9d4bdf6383f094235b6b-33.jpg?alt=media&token=80421bd6-1355-4357-a7b0-99294416bce2'} alt="boohoo" className="img-responsive"/>
          </div>

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L1%2FA22U2L1NREF.pdf?alt=media&token=a3c671b0-b5bd-4605-9c9a-4e1c16f0941e' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
