import React,{useState,useEffect} from 'react'
import { Button,Container} from "semantic-ui-react";
import {Radio} from 'react-bootstrap';
import { map } from "lodash";
// import axios from 'axios';
import { isWaitingAnswers} from '../../utils/Api';
import { toast } from "react-toastify";
import firebase from "../../utils/Firebase";
import Qs from "qs";

import logo from '../../logo.svg';
import './L1work.scss';

const db = firebase.firestore(firebase);

const answer = "https://docs.google.com/spreadsheets/d/1-Zn7XTT-H1t927XbeZW0JCHEcDEeWazafoYbasUP9tQ/edit?resourcekey#gid=2131473131";

const skill= "Vocabulary";

function L1work(props) {

  const{user,myclass,idclass,classname,teachername}=props;
   // console.log(user.uid);
  const [formData, setformData] = useState("");
  //const [myclass, setMyclass] = useState(null);
  const [userDetails, setUserDetails] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [teacherid, setTeacherid] = useState('');
  //console.log(myclass);
  //console.log(userDetails);
  //console.log(idclass);
  ////////input informations  
  //console.log(user);
  //console.log(classname);
 // console.log(teachername);
 

  ////////////////course details user
  useEffect(() => {
      db.collection('waiting')
      .doc(user.uid).get()
      .then(snapshot => setUserDetails(snapshot.data()))
         //   setMyclass(arrayofClasses);
  
  }, [])
  ////////////////
  
  ////////////set is waiting answers  
  useEffect(() => {
      isWaitingAnswers(user.uid).then(response => {
          setWaiting(response);
         // console.log(response);
         });     
    }, [user])
  /////////  
  const onChange = e =>{
    
    // console.log("Key:" +e.target.name);
    // console.log("Value:" +e.target.value);
    setformData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
/////////////send response record
const respuesta =()=>{

 db.collection("answers")
 .add({

   user:user.uid,
   useremail:user.email,
   username:user.displayName,
   userwork:"one",
   usercourse:userDetails.course,
   userlevel:userDetails.level,
   userclassid:idclass,
   userclassname:classname,
   avatarUser: user.photoURL,
   createAt: new Date(),
   Teacher: teachername,
   answerlink:answer,
   studentskill: skill,

   })
   .then(() => {
     toast.success("The responses are already send");
     // resetForm();
     setIsLoading(false);
    // setShowModal(false);
   })
   .catch(() => {
     toast.warning("Error recording the responses.");
     setIsLoading(false);
   }); 
}
///////reset fields
const resetFields = () => { 
    document.getElementById("l1-course-form").reset();
  }
  

//////////////////submit function  
const onSubmit =(e)=> {
//console.log(formData);

var str = Qs.stringify(formData)


 e.preventDefault();
  fetch('https://docs.google.com/forms/d/e/1FAIpQLSeAPuMp9yn1N1vnBplB7WQqLd1TF7ctnmWb6kza1jplE80oCg/formResponse', {
   method: 'post',
   headers: {'Content-Type':'application/x-www-form-urlencoded'},
   mode: 'no-cors',
   body: str,
   redirect: 'follow'
  }).then(() => {
    respuesta();
    resetFields();
  })

 
};
  return (
    <>
           {waiting ? (     

 <div className="App">
 <header className="App-header">
     <form  onChange={onChange} className="trabajo-l1" id="l1-course-form">
     <fieldset>
        <div ><h2 className="L1">A22U2L1N</h2></div> 
    </fieldset>
    <fieldset>
        <div className="parrafo-uno"><p>Responde a las siguientes preguntas.</p></div> 
    </fieldset>
    <fieldset>
        <legend for="" className="leyenda">Email</legend>
        <div class="form-group">
            <input id="emailAddress" type="email" name="emailAddress" class="form-control" required/>
        </div>
    </fieldset>

    


    <fieldset>
        <legend for="1560404928" className="leyenda">1. Marca la opción incorrecta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1787930939" value="montar a caballo." required/>
                    montar a caballo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1787930939" value="montar en coche." required/>
                    montar en coche.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1787930939" value="montar en bicicleta." required/>
                    montar en bicicleta.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="2121608314" className="leyenda">2. Marca la opción correcta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1283365391" value="montar acampada." required/>
                    montar acampada.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1283365391" value="poner la acampada." required/>
                    poner la acampada.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1283365391" value="ir de acampada." required/>
                    ir de acampada.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="1624150703" className="leyenda">3. Hacer senderismo es: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1815609457" value="descansar en el sofá." required/>
                    descansar en el sofá.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1815609457" value="caminar por senderos." required/>
                    caminar por senderos.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1815609457" value="llevar una vida sedentaria." required/>
                    llevar una vida sedentaria.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="151164202" className="leyenda">4. Un hobby es: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.307109908" value="una actividad de tiempo libre." required/>
                    una actividad de tiempo libre.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.307109908" value="un personaje de un libro." required/>
                    un personaje de un libro.
                </label>
            </div>
        </div>
    </fieldset>

    
    <fieldset>
        <legend for="1366707001" className="leyenda">5. En el gimnasio se puede...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.310278380" value="hacer deporte." required/>
                    hacer deporte.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.310278380" value="hacer fotos." required/>
                    hacer fotos.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.310278380" value="cocinar." required/>
                    cocinar.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="12813474" className="leyenda">6. Marca la opción incorrecta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.2000001759" value="Ir a las cartas." required/>
                    Ir a las cartas.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.2000001759" value="Jugar a las cartas." required/>
                    Jugar a las cartas.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.2000001759" value="Hacer las cartas." required/>
                    Hacer las cartas.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="1061044091" className="leyenda">7. Tiempo festivo, marca la incorrecta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.485371203" value="Carnaval." required/>
                    Carnaval.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.485371203" value="Navidad." required/>
                    Navidad.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.485371203" value="Viernes." required/>
                    Viernes.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="189619528" className="leyenda">8. ¿Cuál de estas opciones no es una actividad de tiempo libre? </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1668395067" value="ver la televisión." required/>
                    ver la televisión.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1668395067" value="ver una película." required/>
                    ver una película.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1668395067" value="ver pacientes." required/>
                    ver pacientes.
                </label>
            </div>
        </div>
    </fieldset>

    
    <fieldset>
        <legend for="1807667084" className="leyenda">9. ¿Cuál de estas opciones no es una actividad de tiempo libre? </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1724090557" value="leer novelas." required/>
                    leer novelas.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1724090557" value="leer libros." required/>
                    leer libros.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1724090557" value="leer proyectos." required/>
                    leer proyectos.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="1453472293" className="leyenda">10. Marca la opción incorrecta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1338879507" value="visitar un museo." required/>
                    visitar un museo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1338879507" value="visitar a un amigo." required/>
                    visitar a un amigo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta">
                <input type="radio" name="entry.1338879507" value="visitar un gimnasio." required/>
                    visitar un gimnasio.
                </label>
            </div>
        </div>
    </fieldset>

    



    <input type="hidden" name="fvv" value="1"/>
    <input type="hidden" name="fbzx" value="8461977738504272510"/>

    <input type="hidden" name="pageHistory" value="0"/>

    <div class="center"> 
    <Button className="btn-primary-uno" onClick={onSubmit} isLoading={isLoading}>Send</Button>
    </div>
</form>
</header>
</div>

 ) : (
     <div style={{ backgroundColor: '#101010',height:'260vh'}}>
         <Container>
         <p className="not-assigned">
        You already sent your answers!
        <br></br>
         or
         <br></br>
         don´t have a class assigned yet.
         </p>
         </Container>
     </div>
    )}
    </>
  )
}
export default  L1work;