import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L4.scss'

export default class B11U1L4 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid style={{ backgroundColor: '#101010'}}>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2 className="lesson4title"><br></br>Lesson 4</h2>

        <Slider {...settings}>

        <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-0.jpg?alt=media&token=786f20cd-4988-473f-b92f-1d1ef2d3a584'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-1.jpg?alt=media&token=7a9d03dc-5288-45cd-8610-315cf7c563ef'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-2.jpg?alt=media&token=8dbc9a7e-6663-491a-9d5e-3f165490378f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-3.jpg?alt=media&token=0f3795fc-935c-4734-a674-45847b718e02'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-4.jpg?alt=media&token=ab1f7ffd-823b-469e-ab19-07624d4d6aa4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-5.jpg?alt=media&token=5d2bfdb0-4667-4866-b6b1-9c2a9867fb08'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-6.jpg?alt=media&token=ac628572-92f2-4042-ab17-a7e115b82479'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-7.jpg?alt=media&token=215834cb-58b7-43db-b68c-78616fc474d7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-8.jpg?alt=media&token=bd97b3bd-a09f-4ac7-af1d-62ac538d0f15'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-9.jpg?alt=media&token=3a6ff634-4deb-4ab1-acc9-af44f71e8005'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-10.jpg?alt=media&token=c53f9dc9-9a0e-49ae-81b4-6ce471dee4cd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-11.jpg?alt=media&token=392ec45d-ed41-48c4-aebb-f7ab984c8195'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-12.jpg?alt=media&token=841f9b71-17c4-4625-8cc4-4da64b5a8288'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-13.jpg?alt=media&token=c4545862-b701-49d7-b7a2-c6d215411ce9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-14.jpg?alt=media&token=00aa84f9-99c3-43a7-95a2-89514b058d11'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-15.jpg?alt=media&token=12fcfcda-8b42-4019-b018-2e9d9ff677c6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-16.jpg?alt=media&token=8d60c2ee-6557-47b7-87bd-664dd97fb668'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-17.jpg?alt=media&token=a9180707-6f38-4589-852d-ec1654ff4987'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-18.jpg?alt=media&token=f5898276-6b66-4ec9-94f3-af6cfcd264b3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-19.jpg?alt=media&token=07fe7ae6-349a-4232-a3a2-985f6931e2d5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-20.jpg?alt=media&token=8b3c5fc4-ff66-4cf8-bb82-0da244938cdc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-21.jpg?alt=media&token=96430588-bb5e-4045-b978-98fd21bc4127'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-22.jpg?alt=media&token=0c29d2dc-cabd-4ff5-8d51-b10b8e7b1a4f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-23.jpg?alt=media&token=5619885a-a9b4-40b9-8fc4-185f517e9cdd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-24.jpg?alt=media&token=066a9994-c473-407f-9fbe-4528a5936ba6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-25.jpg?alt=media&token=3dda9067-cdc4-4074-b1c0-e6ed136e5495'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-26.jpg?alt=media&token=97e0ef41-6787-439d-a147-1e50acba2fbc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-27.jpg?alt=media&token=6e58a8f2-55e5-4a57-9e62-bc29710f6682'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2Ff30a6cf693540f0ce381c2c39b60feeb-28.jpg?alt=media&token=dfdd5a69-d4ce-4aff-9b62-09fcd22e7883'} alt="boohoo" className="img-responsive"/>
          </div>

        </Slider>
        <div class="center"> 
        <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/A2.2%2FA2.2%20Unit%202%2FA2.2%20Unit%202%20L4%2FA22U2NMAT.pdf?alt=media&token=dcd2150f-cc11-43c5-aeb3-513be72b2311' download className="btn-download-cuatro">Download resources</Button>
        </div>    
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
