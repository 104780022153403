import React,{useState,useEffect} from 'react'
import { Button,Container} from "semantic-ui-react";
import {Radio} from 'react-bootstrap';
import { toast } from "react-toastify";
import { isWaitingAnswers} from '../../utils/Api';
import { map } from "lodash";
// import axios from 'axios';
import Qs from "qs";
import firebase from "../../utils/Firebase";
import logo from '../../logo.svg';
import './U1Test.scss';

const db = firebase.firestore(firebase);


const answertres = "https://docs.google.com/spreadsheets/d/1aIpuDENhsviah8uqafFWPPA92eCC59oP1qLWAwanVm8/edit?resourcekey#gid=1360686114";

const skilltres= "Test";

function U1Test(props) {

  const{user,setReloadApp,idclass,classname,teachername}=props; 
  //console.log(idclass);
  //console.log(user.uid); 
  const [formData, setformData] = useState("");
  const [waitingtres, setWaitingtres] = useState(false);
  const [userDetailstres, setUserDetailstres] = useState('');
  const [isLoading, setIsLoading] = useState(false);
//  const [teacheridtres, setTeacheridtres] = useState(null);
  //console.log(userDetailstres);
 //console.log(teachername);
//console.log(idclass);
//console.log(classname);
  

////////////////course details user
useEffect(() => {
    db.collection('waiting')
    .doc(user.uid).get()
    .then(snapshot => setUserDetailstres(snapshot.data()))
       //   setMyclass(arrayofClasses);

}, [])
////////////////

    ////////////set is waiting answers  
useEffect(() => {
    isWaitingAnswers(user.uid).then(response => {
        setWaitingtres(response);
       // console.log(response);
       });     
  }, [user])
/////////formdata state  
  
  const onChange = e =>{
    // console.log("Key:" +e.target.name);
    // console.log("Value:" +e.target.value);

    setformData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
  /////////////send response record
const respuesta =()=>{

    db.collection("answers")
    .add({
   
      user:user.uid,
      useremail:user.email,
      username:user.displayName,
      userwork:"U1Test",
      usercourse:userDetailstres.course,
      userlevel:userDetailstres.level,
      userclassid:idclass,
      userclassname:classname,
      avatarUser: user.photoURL,
      createAt: new Date(),
      Teacher: teachername,
      answerlink:answertres,
      studentskill: skilltres,
    
      })
      .then(() => {
        toast.success("The responses are already send");
        // resetForm();
        setIsLoading(false);
       // setShowModal(false);
      })
      .catch(() => {
        toast.warning("Error recording the responses.");
        setIsLoading(false);
      }); 
   }
   ///////reset fields
   const resetFields = () => { 
       document.getElementById("u1-course-form").reset();
     }
  /////////////  

 ///////////delete function
 const borrar =()=>{
    //console.log(cartid);
    db.collection('waiting')
    .doc(user.uid)
    .delete()
    .then(() => {
     // console.log("unit exercises completed!");
      toast.success("You are ready complete the unit exercises.");
      setReloadApp(prevState => !prevState);
      //this.props.history.push("/")
      //window.location.reload();
    }).catch((error) => {
      toast.error("Error completing the unit exercises.");
     // console.error("Error removing document: ", error);
    });
}
 
 ////////// on submit function
  const onSubmit =(e)=> {

  //  console.log(formData);

var str = Qs.stringify(formData)


 e.preventDefault();
  fetch('https://docs.google.com/forms/d/e/1FAIpQLSdgAHAD1XKSpjTbFwEgdfu_FR9RtAfrnq5ua1KdfS2blK_ITA/formResponse', {
   method: 'post',
   mode: 'no-cors',
   headers: {'Content-Type':'application/x-www-form-urlencoded'},
   body: str,
   redirect: 'follow'
  }).then(() => {
   respuesta();
   resetFields();
   borrar();
   setReloadApp(prevState => !prevState);
  })
 

}  
////////
  return (
    <>
 {waitingtres ? (    

 <div className="App">
 <header className="App-header">
 <form  onChange={onChange} className="trabajo-U1" id="u1-course-form">
 <fieldset>
        <div ><h2 class="U1">A22U2 Test</h2></div> 
    </fieldset>
    <fieldset>
        <div class="parrafo-u1"><p>Responde a las preguntas.</p></div> 
    </fieldset>
    

    <fieldset>
        <legend for="" className="leyenda-tres">Email</legend>
        <div class="form-group">
            <input id="emailAddress" type="email" name="emailAddress" class="form-control" required/>
        </div>
    </fieldset>

    <fieldset>
        <legend for="868613428" className="leyenda-tres">1. Para formar el pretérito perfecto necesitamos: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1539278900" value="el verbo haber en presente de indicativo y el participio del verbo." required/>
                    el verbo haber en presente de indicativo y el participio del verbo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1539278900" value="el verbo haber en presente de indicativo y el infinitivo del verbo." required/>
                    el verbo haber en presente de indicativo y el infinitivo del verbo.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="2069084263" className="leyenda-tres">2. Este fin de semana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1585078466" value="he veído una película." required/>
                    he veído una película.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1585078466" value="he visto una película." required/>
                    he visto una película.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="1319333452" className="leyenda-tres">3. Los participios de los verbos se forman principalmente añadiendo: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1625089094" value="- ado, -ido a sus terminaciones." required/>
                    - ado, -ido a sus terminaciones.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1625089094" value="- ando, -iendo a sus terminaciones." required/>
                    - ando, -iendo a sus terminaciones.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="376007107" className="leyenda-tres">4. Este mes...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.498624366" value="hemos motado a caballo." required/>
                    hemos motado a caballo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.498624366" value="habéis montado a caballo." required/>
                    habéis montado a caballo.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="53733750" className="leyenda-tres">5. Esta semana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1158594661" value="voy a ver a mi colega de proyecto." required/>
                    voy a ver a mi colega de proyecto.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1158594661" value="voy ver a mi colega de proyecto." required/>
                    voy ver a mi colega de proyecto.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="449595617" className="leyenda-tres">6. Usamos el pretérito perfecto para: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1222018368" value="hablar de acciones recientes." required/>
                    hablar de acciones recientes.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1222018368" value="hablar de futuro próximo." required/>
                    hablar de futuro próximo.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="1815097056" className="leyenda-tres">7. Esta semana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1467123133" value="habemos descubierto un restaurante nuevo." required/>
                    habemos descubierto un restaurante nuevo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1467123133" value="hemos descubrido un restaurante nuevo." required/>
                    hemos descubrido un restaurante nuevo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1467123133" value="han descubierto un restaurante nuevo." required/>
                    han descubierto un restaurante nuevo.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="552919525" className="leyenda-tres">8. Usamos el pretérito perfecto para...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1359587191" value="preguntar y hablar sobre  experiencias." required/>
                    preguntar y hablar sobre  experiencias.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1359587191" value="hablar sobre  experiencias próximas." required/>
                    hablar sobre  experiencias próximas.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="412196001" className="leyenda-tres">9. Los marcadores de ir a + infinitivo son aquellos que:  </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.2006686318" value="hacen referencia a un tiempo futuro." required/>
                    hacen referencia a un tiempo futuro.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.2006686318" value="hacen referencia a un tiempo pasado." required/>
                    hacen referencia a un tiempo pasado.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="1988495714" className="leyenda-tres">10. Esta semana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1782974249" value="he visitado a mi amiga." required/>
                    he visitado a mi amiga.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-tres">
                <input type="radio" name="entry.1782974249" value="he visitando a mi amiga." required/>
                    he visitando a mi amiga.
                </label>
            </div>
        </div>
    </fieldset>


    <input type="hidden" name="fvv" value="1"/>
    <input type="hidden" name="fbzx" value="-7463818563038847979"/>
   
    <input type="hidden" name="pageHistory" value="0"/>
    <div class="center"> 
    <Button className="btn-primary-tres" onClick={onSubmit} isLoading={isLoading}>Send</Button>
    </div>
</form>
</header>
</div>
) : (
    <div style={{ backgroundColor: '#101010',height:'260vh'}}>
        <Container>
        <p className="not-assigned-tres">
       You already sent your answers!
       <br></br>
        or
        <br></br>
        don´t have a class assigned yet.
        </p>
        </Container>
    </div>
   )}
    </>
  )
}
export default  U1Test;