import React,{useState,useEffect} from 'react'
import { Button,Container} from "semantic-ui-react";
import {Radio} from 'react-bootstrap';
import { isWaitingAnswers} from '../../utils/Api';
import { toast } from "react-toastify";
import { map } from "lodash";
import firebase from "../../utils/Firebase";

// import axios from 'axios';
import Qs from "qs";
import logo from '../../logo.svg';

import './L2work.scss';

const db = firebase.firestore(firebase);

const answerdos = "https://docs.google.com/spreadsheets/d/16UQ_2Jd_s0RYL3HDEijY8bjhnk8Nv8p_Cjy-5Gm--iw/edit?resourcekey#gid=319669290";

const skilldos= "Grammar";

function L2work(props) {

 const{user,idclass,classname,teachername}=props;
  //console.log(user.uid);
 // console.log(idclass);
  const [formData, setformData] = useState("");
  const [waitingdos, setWaitingdos] = useState(false);
  const [userDetailsdos, setUserDetailsdos] = useState('');
  const [isLoading, setIsLoading] = useState(false);
 // const [teacheridos, setTeacheridos] = useState(null);
//console.log(userDetailsdos);
//console.log(teachername);



////////////////course details user
useEffect(() => {
    db.collection('waiting')
    .doc(user.uid).get()
    .then(snapshot => setUserDetailsdos(snapshot.data()))
       //   setMyclass(arrayofClasses);

}, [])
////////////////

////////////set is waiting answers  
useEffect(() => {
    isWaitingAnswers(user.uid).then(response => {
        setWaitingdos(response);
       // console.log(response);
       });     
  }, [user])
/////////  
  
  const onChange = e =>{
    // console.log("Key:" +e.target.name);
    // console.log("Value:" +e.target.value);
    setformData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
 /////////////send response record
const respuesta =()=>{

    db.collection("answers")
    .add({
   
      user:user.uid,
      useremail:user.email,
      username:user.displayName,
      userwork:"two",
      usercourse:userDetailsdos.course,
      userlevel:userDetailsdos.level,
      userclassid:idclass,
      userclassname:classname,
      avatarUser: user.photoURL,
      createAt: new Date(),
      Teacher: teachername,
      answerlink:answerdos,
      studentskill: skilldos,
    
      })
      .then(() => {
        toast.success("The responses are already send");
        // resetForm();
        setIsLoading(false);
       // setShowModal(false);
      })
      .catch(() => {
        toast.warning("Error recording the responses.");
        setIsLoading(false);
      }); 
   }
   ///////reset fields
   const resetFields = () => { 
       document.getElementById("l2-course-form").reset();
     }
  ///////////// 

////submit function  
const onSubmit =(e)=> {

var str = Qs.stringify(formData)


 e.preventDefault();
  fetch('https://docs.google.com/forms/d/e/1FAIpQLSfSfEZ5SHyHa8iCWmPOMWgJ3Q_t9KCPAGA65LLEtM06fBKPeA/formResponse', {
   method: 'post',
   headers: {'Content-Type':'application/x-www-form-urlencoded'},
   mode: 'no-cors',
   body: str,
   redirect: 'follow'
  }).then(() => {
    respuesta();
    resetFields();
  })
  ///e.target.reset();
  
};


  return (
    <>
   {waitingdos ? (     

 <div className="App">
 <header className="App-header">
     <form  onChange={onChange} className="trabajo-l2" id="l2-course-form">
     <fieldset>
        <div ><h2 class="L2">A22U2L2N</h2></div> 
    </fieldset>
    <fieldset>
        <div class="parrafo-dos"><p>Elige la respuesta correcta.</p></div> 
    </fieldset>


    <fieldset>
        <legend for="" className="leyenda-dos">Email</legend>
        <div class="form-group">
            <input id="emailAddress" type="email" name="emailAddress" class="form-control" required/>
        </div>
        
    </fieldset>


    <fieldset>
        <legend for="628478022" className="leyenda-dos">1. Una de estas opciones no es un marcador de pretérito perfecto: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.1896498854" value="esta semana." required/>
                    esta semana.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.1896498854" value="mañana." required/>
                    mañana.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.1896498854" value="hoy." required/>
                    hoy.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="1050549208" className="leyenda-dos">2. Una de estas opciones no es un marcador de futuro próximo: </legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.929916257" value="hoy." required/>
                    hoy.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.929916257" value="este fin de semana pasado." required/>
                    este fin de semana pasado.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.929916257" value="la próxima semana." required/>
                    la próxima semana.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="1342007960" className="leyenda-dos">3. Marca la opción incorrecta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.396659177" value="hacido." required/>
                    hacido.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.396659177" value="comido." required/>
                    comido.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.396659177" value="visto." required/>
                    visto.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="1953934587" className="leyenda-dos">4. Marca la opción incorrecta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.912826308" value="escribido." required/>
                    escribido.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.912826308" value="escrito." required/>
                    escrito.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.912826308" value="leído." required/>
                    leído.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="1081875087" className="leyenda-dos">5. Marca la opción correcta:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.761815333" value="yo voy a ir." required/>
                    yo voy a ir.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.761815333" value="él va a ir." required/>
                    él va a ir.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.761815333" value="nosotros vais a ir." required/>
                    nosotros vais a ir.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="164729838" className="leyenda-dos">6. Esta semana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.702640609" value="he hacido muchas cosas." required/>
                    he hacido muchas cosas.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.702640609" value="he visto una película." required/>
                    he visto una película.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.702640609" value="voy ver una serie." required/>
                    voy ver una serie.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="586216893" className="leyenda-dos">7. Hoy...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.285708129" value="he tenido mucho trabajo." required/>
                    he tenido mucho trabajo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.285708129" value="he leyendo muchos emails." required/>
                    he leyendo muchos emails.
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend for="1215030742" className="leyenda-dos">8. Mañana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.327907276" value="voy a verlo." required/>
                    voy a verlo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.327907276" value="vayamos a verlo." required/>
                    vayamos a verlo.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="617796408" className="leyenda-dos">9. La próxima semana...</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.714025583" value="vamos a ir de excursión." required/>
                    vamos a ir de excursión.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.714025583" value="hemos ido de excursión." required/>
                    hemos ido de excursión.
                </label>
            </div>
        </div>
    </fieldset>


    <fieldset>
        <legend for="1418726897" className="leyenda-dos">10. Para hablar del futuro próximo usamos:</legend>
        <div class="form-group">
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.2084746925" value="ir a + infinitivo." required/>
                    ir a + infinitivo.
                </label>
            </div>
            <div class="radio">
                <label className="etiqueta-dos">
                <input type="radio" name="entry.2084746925" value="pretérito perfecto." required/>
                    pretérito perfecto.
                </label>
            </div>
        </div>
    </fieldset>

    

    <input type="hidden" name="fvv" value="1"/>
    <input type="hidden" name="fbzx" value="6822489034396516105"/>
  
    <input type="hidden" name="pageHistory" value="0"/>
    <div class="center"> 
    <Button className="btn-primary-dos" onClick={onSubmit} isLoading={isLoading}>Send</Button>
    </div>
    </form>
    </header>
    </div>
) : (
    <div style={{ backgroundColor: '#101010',height:'260vh'}}>
        <Container>
        <p className="not-assigned-dos">
       You already sent your answers!
       <br></br>
        or
        <br></br>
        don´t have a class assigned yet.
        </p>
        </Container>
    </div>
   )}
    </>
  )
}
export default  L2work;